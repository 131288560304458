import SxQueryUtils from "../../sxQuery/SxQueryUtils";
import sxQuery from "../../sxQuery/sxQuery";
import { ZoeApiConfigurationRegion } from "../interface/ZoeApiConfigurationRegion";
import UiHelper from "../ui/UiHelper";
var Zoe = /** @class */ (function () {
    function Zoe(region, apiKey, locale, context, identifier, suggest, dev) {
        if (dev === void 0) { dev = false; }
        this.isChatOpen = false;
        this.wasChatRendered = false;
        this.dev = false;
        this.region = region;
        this.apiKey = apiKey;
        this.locale = locale;
        this.identifier = identifier;
        this.suggest = suggest;
        this.context = context;
        this.dev = dev;
    }
    Zoe.prototype.toggleChat = function () {
        if (this.identifier === undefined || this.suggest === undefined) {
            return false;
        }
        if (this.isChatOpen) {
            this.suggest.find('.ss360-zoe-chat').hide();
            this.isChatOpen = false;
            return false;
        }
        if (this.wasChatRendered) {
            this.suggest.find('.ss360-zoe-chat').show();
            this.isChatOpen = true;
            return true;
        }
        UiHelper.renderZoeScript(this.region === ZoeApiConfigurationRegion.BARRACUDA, this.dev);
        var chatWrap = sxQuery('<div class="ss360-zoe-chat"></div>');
        var zoeChat = sxQuery("<zoovu-zoe></zoovu-zoe>");
        zoeChat.attr('mode', 'conversation');
        zoeChat.attr('sku', this.identifier);
        zoeChat.attr('region', this.region);
        zoeChat.attr('api-key', this.apiKey);
        zoeChat.attr('locale', this.locale);
        if (this.dev) {
            zoeChat.attr('dev', 'true');
        }
        var contextString = '{}';
        try {
            contextString = JSON.stringify(this.context);
        }
        catch (err) {
            console.error(err);
        }
        zoeChat.attr('context', contextString);
        chatWrap.append(zoeChat);
        this.suggest.find('.ss360-suggests__content').append(chatWrap);
        this.isChatOpen = true;
        this.wasChatRendered = true;
        return true;
    };
    Zoe.prototype.loadProductComparison = function (identifiers, category, projectId, callback) {
        if (identifiers === undefined || identifiers.length === 0 || category === undefined || projectId === undefined) {
            callback(null);
            return;
        }
        var body = {
            category: category,
            projectId: projectId,
            environment: 'prod',
            products: identifiers,
            context: this.context
        };
        // TODO: use Zoe Base
        SxQueryUtils.post('https://zoovu-msft-demo-chatgpt.azurewebsites.net/api/compareproducts', body, function (data) {
            callback(data);
        }, 'application/json', false, function (err) {
            console.error(err);
            callback(null);
        });
    };
    Zoe.prototype.loadAttributeComparison = function (category, attribute, values, callback) {
        if (category === undefined || attribute === undefined || (values || []).length === 0) {
            callback(null);
        }
        var body = {
            category: category,
            attribute: attribute,
            values: values
        };
        // TODO: use Zoe Base
        SxQueryUtils.post("https://zoovu-msft-demo-chatgpt.azurewebsites.net/api/compareproductattributes", body, function (data) {
            callback(data);
        }, 'application/json', false, function (err) {
            console.error(err);
            callback(null);
        });
    };
    return Zoe;
}());
export default Zoe;
