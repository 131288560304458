'use strict';
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import sxQuery from './sxQuery/sxQuery';
import DefaultConfig from './siteSearch/configuration/DefaultConfig';
import ConfigurationHelper from './siteSearch/configuration/ConfigurationHelper';
import Core from './siteSearch/core';
import Helper from './siteSearch/utils/Helper';
import NavigatorHelper from './siteSearch/utils/NavigatorHelper';
import SubConfigApplier from './siteSearch/utils/SubConfigApplier';
import Logger from './global/Logger';
import SxQueryUtils from './sxQuery/SxQueryUtils';
import FilterPosition from './siteSearch/enums/FilterPosition';
import CallbackEnhanceType from './siteSearch/enums/CallbackEnhanceType';
import SearchResultType from './siteSearch/enums/SearchResultType';
var IS_ZOOVU = ('zoovuMasterSearchConfig' in window) || ('zoovuSearchConfig' in window) || ('zoovuSearchConfigs' in window);
var enhanceCallbacks = function (settings, core) {
    var callbacks = settings.callbacks;
    callbacks.enter = Helper.enhanceCallback(callbacks.enter, core.showResults.bind(core), CallbackEnhanceType.Enter, core.getContext());
    callbacks.focus = Helper.enhanceCallback(callbacks.focus, core.focus.bind(core), CallbackEnhanceType.Focus, core.getContext());
    callbacks.blur = Helper.enhanceCallback(callbacks.blur, core.blur.bind(core), CallbackEnhanceType.Blur, core.getContext());
    callbacks.enterResult = Helper.enhanceCallback(callbacks.enterResult, core.followLink.bind(core), CallbackEnhanceType.EnterResult, core.getContext());
};
var initializeUnibox = function (pluginSettings, core) {
    (function (sxQuery) {
        sxQuery.fn.unibox = function (useFullUnibox, callback, isTrigger) {
            var _this = this;
            if (isTrigger === void 0) { isTrigger = false; }
            var hasMultipleSearchBoxes = this.length > 1;
            if (!useFullUnibox) {
                return this.map(function (idx, searchBox) {
                    searchBox = sxQuery(searchBox);
                    searchBox.off('keyup.uniboxDummy, keydown.uniboxDummy, focus.uniboxDummy, focusout.uniboxDummy');
                    searchBox.on('keyup.uniboxDummy', function (event) {
                        event = event || window.event;
                        var keyCode = event.keyCode || event.which;
                        if (keyCode === 13 && !pluginSettings.results.ignoreEnter) {
                            pluginSettings.callbacks.enter(searchBox.val());
                        }
                    });
                    searchBox.on('keydown.uniboxDummy', undefined, function (e) {
                        pluginSettings.callbacks.type.call(searchBox.get()[0], e, searchBox.val());
                    });
                    searchBox.on('focus.uniboxDummy', undefined, function (e) {
                        pluginSettings.callbacks.focus.call(searchBox.get()[0], e, searchBox.val());
                    });
                    searchBox.on('focusout.uniboxDummy', undefined, function (e) {
                        pluginSettings.callbacks.blur.call(searchBox.get()[0], e, searchBox.val(), true);
                    });
                    if (pluginSettings.searchBox.placeholder !== undefined && searchBox.data('ss360KeepPlaceholder') !== 'true') {
                        searchBox.attr('placeholder', pluginSettings.searchBox.placeholder);
                    }
                    searchBox.getText = function () { return searchBox.val(); };
                    searchBox.updateSuggestUrl = function () { }; // dummy
                    return searchBox;
                });
            }
            // @ts-ignore
            import(/* webpackChunkName: "unibox" */ './unibox/unibox').then(function (_a) {
                var UniBox = _a.default;
                var boxesArray = _this.map(function (idx, searchBox) {
                    searchBox = sxQuery(searchBox);
                    searchBox.off('keyup.uniboxDummy, keydown.uniboxDummy, focus.uniboxDummy, focusout.uniboxDummy');
                    var individualUnibox = new UniBox(isTrigger ? undefined : searchBox, core.getContext(), hasMultipleSearchBoxes, isTrigger ? searchBox : undefined);
                    individualUnibox.init();
                    return individualUnibox;
                });
                if (callback !== undefined) {
                    callback(boxesArray.length === 1 ? boxesArray[0] : boxesArray);
                }
            });
            return undefined;
        };
    }(sxQuery));
};
var handleQueryParams = function (opts, pluginConfiguration) {
    var searchQueryParamName = opts.searchQueryParamName, filtersEnabled = opts.filtersEnabled, searchBoxSelector = opts.searchBoxSelector, highlightSearchTerms = opts.highlightSearchTerms, shouldPopulateSearchBox = opts.shouldPopulateSearchBox, core = opts.core;
    var queryTerm = pluginConfiguration.queryTerm;
    var queryDict = NavigatorHelper.buildQueryDict();
    // have we gotten redirected here? maybe we need to highlight something?
    var highlightedTerm = queryDict.ss360SearchTerm || queryDict.zSearchTerm;
    if (highlightedTerm !== undefined && highlightSearchTerms) {
        try {
            var HIGHLIGHT_CLASS_NAME_1 = 'ss360-search-term-highlight';
            var targetElements_1 = queryTerm.highlightContext === undefined
                ? sxQuery('div, section, main')
                : sxQuery(queryTerm.highlightContext, true).find('div, section, main');
            var highlightTerms = [highlightedTerm];
            if (queryTerm.tokenize) {
                var queryParts = highlightedTerm.split(' ');
                if (queryParts.length > 1) {
                    for (var tokenLength = queryParts.length - 1; tokenLength > 0; tokenLength--) {
                        for (var i = 0; i <= queryParts.length - tokenLength; i++) {
                            var tokenParts = [];
                            for (var j = 0; j < tokenLength; j++) {
                                tokenParts.push(queryParts[j + i]);
                            }
                            highlightTerms.push(tokenParts.join(' '));
                        }
                    }
                }
            }
            highlightTerms.forEach(function (term) {
                targetElements_1.highlight(term, HIGHLIGHT_CLASS_NAME_1);
            });
            // only scroll there if we find exactly one match
            var highlightedPart = sxQuery(".".concat(HIGHLIGHT_CLASS_NAME_1));
            if (highlightedPart.length === 0 && highlightedTerm.indexOf('+') !== -1) { // try replacing all plus signs with a space
                targetElements_1.highlight(highlightedTerm.replace(/\+/g, ' '), HIGHLIGHT_CLASS_NAME_1);
                highlightedPart = sxQuery(".".concat(HIGHLIGHT_CLASS_NAME_1));
            }
            if (queryTerm.faqContext !== undefined && queryDict.qa !== undefined) {
                highlightedPart = sxQuery(queryTerm.faqContext).find(".".concat(HIGHLIGHT_CLASS_NAME_1));
                if (highlightedPart.length === 0) {
                    highlightedPart = sxQuery(".".concat(HIGHLIGHT_CLASS_NAME_1));
                }
            }
            if (highlightedPart.length > 1) {
                var visibleNodes = highlightedPart.get().filter(function (node) {
                    return sxQuery(node).isVisible() && (node.getBoundingClientRect === undefined || node.getBoundingClientRect().height > 0 || node.getBoundingClientRect().width > 0);
                });
                highlightedPart = visibleNodes.length > 0 ? sxQuery(visibleNodes) : highlightedPart;
            }
            var isFAQHighlight = queryDict.qa !== undefined && highlightedPart.length > 0;
            if (highlightedPart.length === 1 || (queryTerm.scrollOnMultiMatch && highlightedPart.length > 0)) {
                var block = queryTerm.scrollIntoViewBlock;
                var isRedirectHighlight = queryDict.zSource === 'redirect' || queryDict.ss360Source === 'redirect';
                var preventScroll = block === 'none' || (isRedirectHighlight && !queryTerm.scrollOnRedirect);
                if (!preventScroll) {
                    var node = highlightedPart.get()[0];
                    node.scrollIntoView({ behavior: queryTerm.scrollIntoViewBehavior, block: block, inline: 'nearest' });
                    // should we try to expand accordion?
                    var parent = highlightedPart.parent();
                    if (parent.get()[0].nodeName !== 'A' || (parent.attr('href') === null || parent.attr('href').length === 0 || parent.attr('href').indexOf('#') === 0)) {
                        parent.get()[0].click();
                    }
                }
            }
            if (isFAQHighlight && pluginConfiguration.callbacks.faqHighlight !== undefined) {
                try {
                    pluginConfiguration.callbacks.faqHighlight(highlightedPart.get());
                }
                catch (err) {
                    console.error(err);
                }
            }
        }
        catch (e) {
            Logger.info(e);
        }
    }
    // is this a search page deep link? Also should be triggered if the user presses back
    var query;
    if (opts.semanticMode) {
        query = NavigatorHelper.getSemanticModeQuery(opts.embedUrl, opts.semanticModeParamName, pluginConfiguration.results);
    }
    else if (queryDict[searchQueryParamName] !== undefined &&
        // shall we prevent matching the query param?
        (core.getSearchResultType() !== SearchResultType.Embed || opts.matchEverywhere || window.location.href.indexOf(opts.embedUrl) !== -1)) {
        query = (queryDict[searchQueryParamName] || core.context.readCookie('LastQuery'));
        if (query !== undefined && query !== null) {
            if (core.getSearchResultType() === SearchResultType.Fullscreen) {
                core.context.getExistingCustomSearchBox().val(query);
                if (core.context.getExistingSearchConsole().css('top') !== '0px') {
                    core.showFullscreenLayer();
                }
            }
            if (core.getSearchResultType() === SearchResultType.Embed && query.indexOf('+') !== -1 && window.location.href.indexOf("".concat(searchQueryParamName, "=").concat(query)) !== -1) { // check if the search param is properly encoded (e.g. personello.de 'photo pillow')
                query = query.replace(/\+/g, ' ');
            }
        }
    }
    if (query !== undefined) {
        if (shouldPopulateSearchBox) {
            sxQuery(searchBoxSelector).val(query);
        }
        var filters = void 0;
        if (filtersEnabled) {
            filters = NavigatorHelper.getFilters(pluginConfiguration.results);
            filters = filters !== undefined && filters.length === 0 ? undefined : filters;
        }
        var sort = void 0;
        var activeGuidedQuestions = [];
        var sortParamName = NavigatorHelper.escapeQueryParam(pluginConfiguration.results.sortingParamName);
        if (queryDict[sortParamName] !== undefined) {
            sort = NavigatorHelper.unescapeQueryParam(queryDict[sortParamName]);
        }
        if (queryDict['ss360-qa-flow']) {
            try {
                activeGuidedQuestions = JSON.parse(decodeURIComponent(queryDict['ss360-qa-flow']));
            }
            catch (err) {
                // ccl
            }
        }
        ;
        core.showResults(query, {
            filters: filters,
            shouldPushState: false,
            submitSource: 'pageload',
            sort: sort,
            activeGuidedQuestions: activeGuidedQuestions
        });
    }
};
var getPublicInstance = function (core) {
    return {
        changeConfig: core.changeConfig.bind(core),
        setSiteId: core.setSiteId.bind(core),
        init: core.init.bind(core),
        showResults: core.showResults.bind(core),
        showFullscreenLayer: core.showFullscreenLayer.bind(core),
        closeLayer: core.closeLayer.bind(core),
        showLoading: core.showLoading.bind(core),
        hideLoading: core.hideLoading.bind(core),
        isInitialized: core.isInitialized.bind(core),
        setBaseUrl: core.setBaseUrl.bind(core),
        setSuggestUrl: core.setSuggestUrl.bind(core),
        renderResults: core.renderResults.bind(core),
        openTab: core.openTab.bind(core),
        hideSearchResults: core.hideSearchResults.bind(core),
        destroy: core.destroy.bind(core),
        getVersion: function () {
            return core.VERSION;
        },
        getSettings: function () {
            return core.getSettings(); // FIXME
        }
    };
};
var loadTranslations = function (lang, callback) {
    if (lang === undefined || lang === null) {
        callback({});
        return;
    }
    lang = lang.toString().trim().toLowerCase();
    if (lang === 'en' || lang.length === 0) {
        callback({});
        return;
    }
    // @ts-ignore
    import(/* webpackChunkName: "translations" */ './siteSearch/configuration/ConfigTranslations').then(function (_a) {
        var ConfigTranslations = _a.default;
        var languageMap = {
            fr: ConfigTranslations.FR,
            de: ConfigTranslations.DE,
            nl: ConfigTranslations.NL,
            tr: ConfigTranslations.TR,
            it: ConfigTranslations.IT,
            pl: ConfigTranslations.PL,
            es: ConfigTranslations.ES,
            'es-mx': ConfigTranslations.ES_MX,
            'es_mx': ConfigTranslations.ES_MX,
            pt: ConfigTranslations.PT,
            da: ConfigTranslations.DA,
            fi: ConfigTranslations.FI,
            sv: ConfigTranslations.SV,
            no: ConfigTranslations.NO,
            lt: ConfigTranslations.LT,
            ro: ConfigTranslations.RO,
            lv: ConfigTranslations.LV,
            el: ConfigTranslations.EL,
            hu: ConfigTranslations.HU,
            cz: ConfigTranslations.CZ,
            ru: ConfigTranslations.RU,
            is: ConfigTranslations.IS,
            ja: ConfigTranslations.JA,
            ko: ConfigTranslations.KO
        };
        var translations = languageMap[lang];
        callback(translations);
    });
};
var init = function (config, isDefaultInstance, callback) {
    if (isDefaultInstance === void 0) { isDefaultInstance = true; }
    if (callback === void 0) { callback = function () { }; }
    if (config === undefined) {
        Logger.warn("".concat(IS_ZOOVU ? 'zoovuSearchConfig' : 'ss360Config', " object is not defined. The document.location.host will be used as siteId and '#searchBox' as search box selector."));
        config = {};
    }
    if (config.siteId === undefined) {
        config.siteId = document.location.host;
    }
    if (config.disableInfoLogs === true) {
        if (config.loggers === undefined) {
            config.loggers = {};
        }
        if (config.loggers.info === undefined) {
            config.loggers.info = function () { };
        }
    }
    if (config.loggers !== undefined) {
        if (config.loggers.info !== undefined) {
            Logger.set('info', config.loggers.info);
        }
        if (config.loggers.warn !== undefined) {
            Logger.set('warn', config.loggers.warn);
        }
        if (config.loggers.error !== undefined) {
            Logger.set('error', config.loggers.error);
        }
    }
    var defaults = Helper.copyObject(DefaultConfig);
    var always = function () {
        // @ts-ignore
        var pluginSettings = SxQueryUtils.extend(defaults, config);
        if (pluginSettings.filters.enabled && config.filters.position === undefined && pluginSettings.results.embedConfig === undefined && pluginSettings.results.fullScreenConfig === undefined) { // filter position top, if layover
            pluginSettings.filters.position = FilterPosition.Top;
        }
        if (config.layout !== undefined && config.layout.masonryCols !== undefined) {
            pluginSettings.layout.masonryCols = Helper.copyObject(config.layout.masonryCols);
        }
        if (pluginSettings.style.defaultCss) {
            // eslint-disable-next-line global-require
            var css = require('../sass/siteSearch/critical.scss'); // load the default css
            var $head = sxQuery('head');
            $head.find('#ss360custom-search-input').remove();
            $head.append("<style id=\"ss360custom-search-input\" type=\"text/css\">".concat(css, "</style>"));
        }
        ConfigurationHelper.assert(config, pluginSettings);
        var core = new Core(pluginSettings, isDefaultInstance, IS_ZOOVU);
        SubConfigApplier.init(core.getContext());
        SubConfigApplier.apply(core.getContext());
        // make sure all calbacks work as expected
        enhanceCallbacks(pluginSettings, core);
        core.setIsZoovu(IS_ZOOVU);
        // expose public methods
        var publicInstance = getPublicInstance(core);
        if (isDefaultInstance && !IS_ZOOVU) {
            window.SS360 = publicInstance;
        }
        else if (isDefaultInstance && IS_ZOOVU) {
            window.ZOOVU_SEARCH = publicInstance;
        }
        else if (pluginSettings.alias !== undefined) {
            window[pluginSettings.alias] = publicInstance;
        }
        if (pluginSettings.relatedContent !== undefined && (pluginSettings.relatedContent.active === undefined || pluginSettings.relatedContent.active === true)) {
            // @ts-ignore
            import(/* webpackChunkName: "relatedContent" */ './relatedContent/relatedContent').then(function (_a) {
                var RelatedContent = _a.default;
                var relatedContent = new RelatedContent(__assign(__assign({}, pluginSettings.relatedContent), { themeColor: pluginSettings.style.accentColor, siteId: pluginSettings.siteId, ecom: pluginSettings.ecom, ecomDev: pluginSettings.ecomDev, ecomStage: pluginSettings.ecomStage, baseUrl: pluginSettings.baseUrl, _cacheQueries: pluginSettings._cacheQueries, _logQueries: pluginSettings.tracking.logQueries }), core.getContext().getInsights(), IS_ZOOVU);
                sxQuery(document).ready(function () {
                    relatedContent.loadAndRender();
                });
            });
        }
        // init
        sxQuery(document).ready(function () {
            initializeUnibox(pluginSettings, core, config);
            core.init();
            if (pluginSettings.style.defaultCss) {
                // @ts-ignore
                import(/* webpackChunkName: "styles" */ './siteSearch/styles/Styles').then(function (_a) {
                    var Styles = _a.default;
                    Styles.renderDefaultStyles(pluginSettings, core.context.layerId, isDefaultInstance ? '' : core.getContext().alias);
                });
            }
            var shouldPopulateSearchBox = pluginSettings.results.embedConfig === undefined
                || pluginSettings.results.embedConfig.populateSearchBoxOnRedirect === undefined
                || pluginSettings.results.embedConfig.populateSearchBoxOnRedirect !== false;
            var embedUrl = pluginSettings.results.embedConfig !== undefined ? pluginSettings.results.embedConfig.url : undefined;
            var opts = {
                searchQueryParamName: pluginSettings.results.searchQueryParamName,
                filtersEnabled: pluginSettings.filters.enabled,
                searchBoxSelector: pluginSettings.searchBox.selector,
                highlightSearchTerms: pluginSettings.queryTerm.highlight,
                semanticMode: pluginSettings.results.semanticMode,
                semanticModeParamName: pluginSettings.results.semanticModeParamName,
                embedUrl: embedUrl,
                matchEverywhere: pluginSettings.results.embedConfig === undefined || pluginSettings.results.embedConfig.matchOnEveryPage !== false || embedUrl === undefined || embedUrl.length === 0,
                shouldPopulateSearchBox: shouldPopulateSearchBox,
                core: core
            };
            handleQueryParams(opts, pluginSettings);
            if (callback) {
                callback(core);
            }
        });
    };
    if (config.callbacks !== undefined && config.callbacks.preInit !== undefined) {
        try {
            config.callbacks.preInit(config);
        }
        catch (err) {
            Logger.error(err);
        }
    }
    loadTranslations(config.language, function (translations) {
        if (translations !== undefined && Object.keys(translations).length > 0) {
            defaults = SxQueryUtils.extend(defaults, translations);
            var noResultsPageTranslations_1 = translations.noResultsPage;
            // @ts-ignore
            if (defaults.noResultsPage && (defaults.noResultsPage.content || []).length > 0) {
                // @ts-ignore
                var noResultsPageContent = defaults.noResultsPage.content;
                noResultsPageContent.forEach(function (entry) {
                    if (noResultsPageTranslations_1[entry.type] !== undefined) {
                        entry.title = noResultsPageTranslations_1[entry.type];
                    }
                    if (entry.fallback && noResultsPageTranslations_1[entry.fallback.type] !== undefined) {
                        entry.fallback.title = noResultsPageTranslations_1[entry.fallback.type];
                    }
                });
            }
        }
        always();
    });
};
window.sxQuery = sxQuery; // make sxQuery public
var initializeSs360 = function (config) {
    config = config || (IS_ZOOVU ? window.zoovuSearchConfig : window.ss360Config);
    var firstConfig = config;
    if (config instanceof Array) {
        firstConfig = config[0];
        config.forEach(function (singleConfig, idx) {
            if (idx !== 0 && singleConfig.alias === undefined) {
                singleConfig.alias = "".concat(IS_ZOOVU ? 'ZOOVU_SEARCH' : 'SS360', "_").concat(idx);
            }
            init(singleConfig, idx === 0);
        });
    }
    else if (config !== undefined || !(('ss360Configs' in window) || ('zoovuSearchConfigs' in window))) {
        init(config, true);
    }
    if (('ss360Configs' in window) || ('zoovuSearchConfigs' in window)) {
        var configs_1 = ('ss360Configs' in window) ? window.ss360Configs : window.zoovuSearchConfigs;
        Object.keys(configs_1).forEach(function (key) {
            var singleConfig = configs_1[key];
            if (firstConfig === undefined || firstConfig === null) {
                firstConfig = singleConfig;
            }
            if (singleConfig.alias === undefined) {
                singleConfig.alias = key;
            }
            init(singleConfig, false);
        });
    }
    var landingPageNode = document.getElementById('zoovu-search-landing-page-config') || document.getElementById('ss360-search-landing-page-config');
    if (landingPageNode !== null) {
        try {
            var landingPageConfig_1 = JSON.parse(landingPageNode.innerText);
            if (landingPageConfig_1.query || landingPageConfig_1.breadcrumb) {
                landingPageConfig_1 = SxQueryUtils.extend(Helper.deepCopyObject(firstConfig || {}), landingPageConfig_1);
                loadTranslations(landingPageConfig_1.language, function (translations) {
                    var defaults = Helper.deepCopyObject(DefaultConfig);
                    if (translations !== undefined && Object.keys(translations).length > 0) {
                        defaults = SxQueryUtils.extend(defaults, translations);
                    }
                    landingPageConfig_1 = SxQueryUtils.extend(defaults, landingPageConfig_1);
                    // @ts-ignore
                    landingPageConfig_1.searchBox = landingPageConfig_1.searchBox || {};
                    landingPageConfig_1.searchBox._preventBind = true;
                    landingPageConfig_1.searchBox.selector = undefined;
                    landingPageConfig_1.searchBox.searchButton = undefined;
                    // @ts-ignore
                    landingPageConfig_1.results = landingPageConfig_1.results || {};
                    landingPageConfig_1.results.searchQueryParamName = 'ss360-landing-page-q';
                    landingPageConfig_1.alias = 'ss360LandingPageSearch';
                    var query = landingPageConfig_1.query;
                    var queryDict = NavigatorHelper.buildQueryDict();
                    if (query === undefined || query === null || query.length === 0) {
                        query = landingPageConfig_1.breadcrumb.length > 0 ? "_#".concat(landingPageConfig_1.breadcrumb.join('#')) : '_#';
                    }
                    var filters;
                    if (landingPageConfig_1.filters && landingPageConfig_1.filters.enabled) {
                        filters = NavigatorHelper.getFilters(landingPageConfig_1.results);
                        filters = filters !== undefined && filters.length === 0 ? undefined : filters;
                    }
                    var sort;
                    var activeGuidedQuestions = [];
                    var sortParamName = NavigatorHelper.escapeQueryParam(landingPageConfig_1.results.sortingParamName);
                    if (queryDict[sortParamName] !== undefined) {
                        sort = NavigatorHelper.unescapeQueryParam(queryDict[sortParamName]);
                    }
                    if (queryDict['ss360-qa-flow']) {
                        try {
                            activeGuidedQuestions = JSON.parse(decodeURIComponent(queryDict['ss360-qa-flow']));
                        }
                        catch (err) {
                            // ccl
                        }
                    }
                    ;
                    init(landingPageConfig_1, false, function (core) {
                        // @ts-ignore
                        window[landingPageConfig_1.alias] = getPublicInstance(core);
                        core.showResults(query, {
                            shouldPushState: false,
                            submitSource: 'landing-page',
                            filters: filters,
                            sort: sort,
                            activeGuidedQuestions: activeGuidedQuestions
                        });
                    });
                });
            }
        }
        catch (err) {
            console.error(err);
        }
    }
};
if (!IS_ZOOVU) {
    window.initializeSs360 = initializeSs360;
}
else {
    window.initializeZoovuSearch = initializeSs360;
}
if ('ss360MasterConfig' in window) {
    window.ss360Config = window.ss360MasterConfig;
    if ('ss360Configs' in window) {
        window.ss360Configs = {};
    }
}
if ('zoovuMasterSearchConfig' in window) {
    window.zoovuSearchConfig = window.zoovuMasterSearchConfig;
    if ('zoovuSearchConfigs' in window) {
        window.zoovuSearchConfigs = {};
    }
}
/** SS360 ends here **/
var initSearch = function (alias) {
    if (!(alias in window) || window[alias] === undefined || window[alias].autoInit !== false) {
        if (alias in window || "".concat(alias, "s") in window) {
            initializeSs360();
        }
        else {
            sxQuery(document).ready(function () {
                initializeSs360();
            });
        }
    }
};
initSearch(IS_ZOOVU ? 'zoovuSearchConfig' : 'ss360Config');
