import StringHelper from "../../global/StringHelper";
import SxQueryUtils from "../../sxQuery/SxQueryUtils";
import sxQuery from "../../sxQuery/sxQuery";
import Helper from "../utils/Helper";
import Icons from "./Icons";
export default {
    render: function (guidedQuestions, context, data, preventReload, loadStartTs) {
        var _this = this;
        if (preventReload === void 0) { preventReload = false; }
        if (loadStartTs === void 0) { loadStartTs = new Date().getTime(); }
        guidedQuestions = guidedQuestions.filter(function (question) { return question.answers.length > 0; });
        var $layer = context.getExistingLayer();
        $layer.find('.ss360-guided-questions').remove();
        var guidedQuestionsTicketId = data.getGuidedQuestionsTicketId();
        if (guidedQuestions.length === 0 && (guidedQuestionsTicketId === undefined || guidedQuestionsTicketId === null || preventReload)) {
            return;
        }
        var isPlaceholder = false;
        if (guidedQuestions.length === 0) {
            isPlaceholder = true;
            guidedQuestions = [{
                    question: '',
                    answers: new Array(4).join(',').split(',').map(function () { return ({ answer_text: '', id: -1 }); })
                }];
            var baseUrl = context.getSearchBase();
            context.setLastRequestedGuidedQuestionTicketId(guidedQuestionsTicketId);
            var isSameContext_1 = function () { return context.getLastRequestedGuidedQuestionTicketId() === guidedQuestionsTicketId; };
            SxQueryUtils.get("".concat(baseUrl, "/search/questions/").concat(guidedQuestionsTicketId, "?projectId=").concat(context.pluginConfiguration.siteId), function (res) {
                if (!isSameContext_1()) {
                    return;
                }
                if (res && res.length > 0) {
                    _this.render(res, context, data, true, loadStartTs);
                }
                else {
                    $layer.find('.ss360-guided-questions').remove();
                }
            }, function () {
                if (isSameContext_1()) {
                    $layer.find('.ss360-guided-questions').remove();
                }
            });
        }
        var wrapper = sxQuery('<section class="ss360-n-section ss360-guided-questions"></wrapper>');
        var _a = Helper.colorToRgb(context.pluginConfiguration.style.accentColor), r = _a.r, g = _a.g, b = _a.b;
        var bgColor = "rgba(".concat(r, ",").concat(g, ",").concat(b, ",0.2)");
        guidedQuestions.forEach(function (question) {
            var rowWrapper = sxQuery('<div class="ss360-guided-questions__row"></div>');
            if (isPlaceholder) {
                rowWrapper.attr('aria-busy', 'true');
            }
            rowWrapper.css('backgroundColor', bgColor);
            var $title = sxQuery("<span class=\"ss360-guided-questions__question ss360-ac-c\">".concat(isPlaceholder ? '' : Icons.getZoeIcon()).concat(StringHelper.escapeHtml(question.question), "</span>"));
            if (isPlaceholder) {
                $title.addClass('ss360-guided-questions__question--placeholder ss360-shimmer');
            }
            rowWrapper.append($title);
            var viewId = Math.round(Math.random() * 1000000);
            question.answers.forEach(function (answer, idx) {
                var tagName = isPlaceholder ? 'span' : 'button';
                var button = sxQuery("<".concat(tagName, " class=\"ss360-n-button ss360-guided-questions__answer\" type=\"button\">").concat(StringHelper.escapeHtml(answer.answer_text), "</").concat(tagName, ">"));
                if (isPlaceholder) {
                    button.addClass('ss360-guided-questions__answer--placeholder ss360-shimmer');
                }
                if (answer.explanation) {
                    button.addClass('ss360-guided-questions__answer--has-tooltip');
                    button.append("<span class=\"ss360-guided-questions__tooltip-icon\">".concat(Icons.getSvgIcon(Icons.INFO, '#a1a7b9', 'ss360-guided-questions__tooltip-icon-svg', 16, 16), "</span>"));
                    button.append(" <span class=\"ss360-guided-questions__tooltip\">".concat(StringHelper.escapeHtml(answer.explanation), "</span>"));
                }
                button.on('click', function () {
                    if (isPlaceholder) {
                        return;
                    }
                    if (context.getInsights()) {
                        context.getInsights().trackGuidedQuestionClicked(data.query, viewId, idx, answer.id);
                    }
                    var id = answer.id, short_name = answer.short_name;
                    var filters = data.plain.activeFilterOptions;
                    var activeGuidedQuestions = data.activeGuidedQuestions || [];
                    activeGuidedQuestions.push({
                        questionId: question.id,
                        answerId: id,
                        name: short_name
                    });
                    context.core.showResults(data.query, {
                        filters: filters,
                        sort: data.plain.sorting,
                        submitSource: 'guided-question',
                        activeGuidedQuestions: activeGuidedQuestions
                    });
                });
                rowWrapper.append(button);
            });
            if (!isPlaceholder && context.getInsights()) {
                context.getInsights().trackGuidedQuestionLoaded(data.query, question._id || question.id, viewId, new Date().getTime() - loadStartTs);
                rowWrapper.onScrolledIntoViewport(function () {
                    context.getInsights().trackGuidedQuestionViewed(data.query, viewId);
                });
            }
            wrapper.append(rowWrapper);
        });
        var $layerContent = $layer.find('.ss360-layer__content');
        var $heading = $layerContent.find('.ss360-layer__heading');
        if ($heading.length === 0 || !$heading.get(0).nextElementSibling) {
            $layerContent.prepend(wrapper);
        }
        else {
            $layerContent.get(0).insertBefore(wrapper.get(0), $heading.get(0).nextElementSibling);
        }
    }
};
