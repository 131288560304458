'use strict';
import sxQuery from "../../sxQuery/sxQuery";
import NoResultsPageBlockType from "../enums/NoResultsPageBlockType";
import Icons from "./Icons";
import Results from "./Results";
import ApiConfig from "../api/ApiConfig";
import SxQueryUtils from "../../sxQuery/SxQueryUtils";
import SearchResponse from "../model/SearchResponse";
import ImageHandler from "../utils/ImageHandler";
import SearchResultType from "../enums/SearchResultType";
import RelatedQueries from "./RelatedQueries";
var NoResultsPage = /** @class */ (function () {
    function NoResultsPage(context, query, uiBuilder, relatedQueries) {
        this.context = context;
        this.query = query;
        this.uiBuilder = uiBuilder;
        this.baseUrl = context.getSearchBase();
        this.relatedQueries = relatedQueries;
    }
    NoResultsPage.prototype.render = function (layerContent) {
        var _this = this;
        var _a = this, query = _a.query, context = _a.context;
        var resultHeadingLevel = context.pluginConfiguration.accessibility.resultTopHeadingLevel;
        var noResultsPage = context.pluginConfiguration.noResultsPage;
        var wrap = sxQuery("<div id=\"".concat(context.noResultsLayerId, "\" class=\"ss360-no-results\"></div>"));
        if (noResultsPage.title || noResultsPage.description) {
            layerContent.find('.ss360-layer__heading').remove();
            var header = sxQuery('<header class="ss360-n-section ss360-no-results__header"></header>');
            var headerText = sxQuery('<div class="ss360-no-results__header-text"></div>');
            header.append("<i role=\"presentation\" class=\"ss360-no-results__icon\">".concat(Icons.getNoResultsIcon(''), "</i>"));
            if (noResultsPage.title) {
                headerText.append("<h".concat(resultHeadingLevel, " id=\"").concat(context.searchResultHeadingId, "\" class=\"ss360-layer__heading ss360-no-results__title\">\n                                        <a class=\"ss360-layer__heading-anchor\" tabindex=\"-1\" href=\"#\">").concat(noResultsPage.title.replace(/#QUERY#/g, query), "</a>\n                                    </h").concat(resultHeadingLevel, ">"));
            }
            if (noResultsPage.description) {
                headerText.append("<p class=\"ss360-no-results__description\">".concat(noResultsPage.description.replace(/#QUERY#/g, query), "</p>"));
            }
            header.append(headerText);
            wrap.append(header);
        }
        var renderedBlocks = 0;
        noResultsPage.content.forEach(function (block) {
            _this.appendBlock(wrap, block, undefined, function () {
                renderedBlocks++;
                if (noResultsPage.content.length === renderedBlocks && context.pluginConfiguration.callbacks.noResultsPageRendered) {
                    context.pluginConfiguration.callbacks.noResultsPageRendered();
                }
            });
        });
        layerContent.append(wrap);
    };
    NoResultsPage.prototype.appendBlock = function (wrap, block, existingBlock, renderCallback) {
        var _this = this;
        renderCallback = renderCallback || function () { };
        var pluginConfiguration = this.context.pluginConfiguration;
        var headingLevel = pluginConfiguration.accessibility.resultTopHeadingLevel;
        var blockWrap = existingBlock !== undefined ? existingBlock : sxQuery("<div class=\"ss360-no-results__block ss360-no-results__block--".concat(block.type, "\"></div>"));
        if (existingBlock !== undefined) {
            existingBlock.html('');
        }
        var title = block.title;
        if (block.type === NoResultsPageBlockType.RelatedQueries) {
            title = this.context.pluginConfiguration.results.relatedQueriesTitle;
            if (title[title.length - 1] === ':') {
                title = title.substring(0, title.length - 1);
            }
        }
        if (title) {
            blockWrap.append("<h".concat(headingLevel, " class=\"ss360-no-results__subtitle\">").concat(title, "</h").concat(headingLevel, ">"));
        }
        if (block.type === NoResultsPageBlockType.CustomHtml) {
            blockWrap.html(block.value);
            renderCallback();
        }
        else if (block.type === NoResultsPageBlockType.SearchBox) {
            var ss360SearchWrapper = this.uiBuilder.buildSearchField('', true, false, '--no-results');
            ss360SearchWrapper.addClass('ss360-no-results__search');
            ss360SearchWrapper.find('.ss360-custom-search__searchbox').addClass('ss360-no-results__search-box');
            blockWrap.append(ss360SearchWrapper);
            renderCallback();
        }
        else if (block.type === NoResultsPageBlockType.FuzzyResults) {
            blockWrap.hide();
            Results.fetch(this.context.core.buildQueryUrl({
                limit: 3,
                include: pluginConfiguration.contentGroups.include,
                exclude: pluginConfiguration.contentGroups.exclude,
                tracking: false,
                query: this.query,
                offset: 0,
                limitPerGroup: false,
                isPlain: false,
                sort: "",
                group: false,
                fuzziness: this.context.isEcom() ? 3 : 5
            }), this.context, function (data) {
                if (data.totalResults === 0 && block.fallback !== undefined) {
                    blockWrap.removeClass("ss360-no-results__block--".concat(block.type)).addClass("ss360-no-results__block--".concat(block.fallback.type));
                    _this.appendBlock(wrap, block.fallback, blockWrap, renderCallback);
                }
                else if (data.totalResults === 0) {
                    blockWrap.remove();
                    renderCallback();
                }
                else {
                    _this.renderResults(data.getFlatSuggests().splice(0, 6), blockWrap);
                    blockWrap.show();
                    renderCallback();
                }
            });
        }
        else if (block.type === NoResultsPageBlockType.PopularResults) {
            blockWrap.hide();
            var siteId = pluginConfiguration.siteId;
            var limit_1 = 3;
            var queryUrl = this.context.isEcom() ? "".concat(this.baseUrl, "/search/top-products?site=").concat(siteId, "&projectId=").concat(siteId, "&limit=").concat(limit_1 * 10) : "".concat(this.baseUrl, "/sites/suggest/popularResults?site=").concat(siteId, "&limit=").concat(limit_1);
            SxQueryUtils.get(queryUrl, function (data) {
                var response = new SearchResponse(data);
                var flatSuggests = response.getFlatSuggests().splice(0, limit_1);
                if (flatSuggests.length === 0) {
                    blockWrap.remove();
                }
                else {
                    _this.renderResults(flatSuggests, blockWrap);
                    blockWrap.show();
                }
                renderCallback();
            });
        }
        else if (block.type === NoResultsPageBlockType.PopularQueries) {
            blockWrap.hide();
            var suggestUrl = new ApiConfig(this.baseUrl, this.context.isEcom(), pluginConfiguration.siteId).suggestBaseUrl;
            if (suggestUrl.indexOf('?') === -1) {
                suggestUrl = "".concat(suggestUrl, "?");
            }
            else {
                suggestUrl = "".concat(suggestUrl, "&");
            }
            SxQueryUtils.get("".concat(suggestUrl, "log=false&maxQuerySuggestions=9&limit=9&site=").concat(pluginConfiguration.siteId, "&query="), function (data) {
                var response = new SearchResponse(data);
                var flatSuggests = response.getFlatSuggests();
                if (flatSuggests.length === 0) {
                    blockWrap.remove();
                }
                else {
                    var suggestsList_1 = sxQuery('<ul class="ss360-no-results__popular-queries"></ul>');
                    response.getFlatSuggests().forEach(function (suggest) {
                        var href = _this.context.getSearchResultType() === SearchResultType.Embed ? pluginConfiguration.results.embedConfig.url : '';
                        href = href || '';
                        if (href.indexOf('?') === -1) {
                            href = "".concat(href, "?");
                        }
                        else {
                            href = "".concat(href, "&");
                        }
                        href = "".concat(href).concat(encodeURIComponent(pluginConfiguration.results.searchQueryParamName), "=").concat(encodeURIComponent(suggest.getName()));
                        var icon = "<i class=\"ss360-no-results__popular-query-icon\" role=\"presentation\">".concat(Icons.getSvgIcon(Icons.MAGNIFIER, pluginConfiguration.style.accentColor, undefined, 18, 18), "</i>");
                        var $li = sxQuery("<li class=\"ss360-no-results__popular-queries-item\"><a href=\"".concat(href, "\" class=\"ss360-no-results__popular-query\">").concat(icon).concat(suggest.getName(), "</a></li>"));
                        $li.find('.ss360-no-results__popular-query').on('click', function (e) {
                            if (!e.ctrlKey) {
                                e.stopPropagation();
                                e.preventDefault();
                                _this.context.core.showResults(suggest.getName());
                            }
                        });
                        suggestsList_1.append($li);
                    });
                    blockWrap.append(suggestsList_1);
                    blockWrap.show();
                }
            });
            renderCallback();
        }
        else if (block.type === NoResultsPageBlockType.TextList) {
            var $ul_1 = sxQuery('<ul class="ss360-no-results__list"></ul>');
            var values = block.value instanceof Array ? block.value : [block.value];
            values.forEach(function (value) {
                $ul_1.append("<li class=\"ss360-no-results__list-item\">".concat(value, "</li>"));
            });
            blockWrap.append($ul_1);
            renderCallback();
        }
        else if (block.type === NoResultsPageBlockType.RelatedQueries) {
            if (this.relatedQueries === undefined || this.relatedQueries.length === 0) {
                blockWrap.hide();
            }
            else {
                new RelatedQueries(this.relatedQueries, this.context).render(sxQuery('#ss360-layer'), false, blockWrap);
            }
            renderCallback();
        }
        if (existingBlock === undefined) {
            wrap.append(blockWrap);
        }
    };
    NoResultsPage.prototype.renderResults = function (suggests, wrap) {
        var _this = this;
        var resultsWrap = sxQuery('<ul class="ss360-no-results__alternative-results ss360-list ss360-grid ss360-grid--lg ss360-grid--sm"></ul>');
        suggests.forEach(function (suggest) {
            var $item = _this.uiBuilder.buildSuggestItem(suggest, _this.query, '_');
            resultsWrap.append($item);
        });
        wrap.append(resultsWrap);
        ImageHandler(this.context);
    };
    return NoResultsPage;
}());
export default NoResultsPage;
