'use strict';

const DefaultConfig = {
	showErrors: false, // whether to show implementation errors, set to false for production
	allowCookies: true, // whether to allow the javascript to set cookies
	suggestions: {
		show: true, // whether to show search suggestions
		trigger: undefined, // a suggestion overlay trigger
		showOnMobile: true, // whether to show seearch suggestions on mobile devices (less than 768px), default: true
		url: '', // the URL that provides the data for the suggest
		maxQuerySuggestions: 3, // the maximum number of query suggestions
		querySuggestionHeadline: undefined, // the headline of the query suggestions, leave blank if no headline should be shown
		emptyQuerySuggestions: undefined, // suggestions to be shown if the search box is empty, a JSON object mapping content group names to array of suggestions, e.g. suggests:{_:[{name:"The Title",link:"https://mylink.de/",image:"https://placekitten.com/150/150"}]}
		showImages: true, // show images in search suggestions
		equalSearch: false, // whether suggestions should be handled separately or whether the search results should be the suggestions
		num: 6, // the maximum number of search suggestions to be shown
		minChars: 3, // minimum number of characters before the suggestions shows, default: 3,
		maxWidth: 'auto', // the maximum width of the suggest box, default: as wide as the input box, at least 275px
		throttleTime: 300, // the number of milliseconds before the suggest is triggered after finished input, default: 300ms
		highlight: true, // whether matched words should be highlighted, default: true
		viewAllLabel: 'Show all results', // the label of a 'View All' button shown at the end of the suggestion list, default: undefined - no 'View All' button will be added
		forceBelow: false, // whether to force the suggestions to be shown below the search box (default: false)
		mobileScrollOnFocus: true, // whether to scroll the page in order for the search box to be to the top of the window (on screens below 768 px)
		triggersSearch: false, // whether to trigger the full search instead of search suggestions
		viewKeyMappings: undefined,
		suggestTemplate: {
			template: undefined,
			preRenderCallback: undefined,
			templateBuiltCallback: undefined,
			postRenderCallback: undefined
		},
		fuzziness: undefined, // the suggestion fuzziness (between 0 and 5)
		maxSuggestions: {}, // a mapping of content group names to max number of suggestions to be shown, this setting has higher priority than contentGroups.include and data-ss360-include attribute, only content groups from this setting (if not empty) will be shown in search suggestions
		maxSearchHistoryEntries: 5, // how many recent queries to show when the user focuses a search box (set to 0 to disable)
		searchHistoryLabel: 'You recently searched for:', // the label of recent queries
		linksOpenNewTab: false, // should clicking on the result links open a new tab/window?
		source: {
			emptyState: {
				layoutType: 1,
				content: [
					{
						type: 'searchHistory',
						categoryStyle: 'titles',
						style: 'compact'
					}
				]
			},
			queryBased: {
				layoutType: 1,
				content: [
					{
						type: 'resultGroup',
						categoryStyle: 'titles',
						style: 'compact'
					}
				]
			}
		},
		allResultsName: 'All', // the name of the all results tab
		focusLayer: false,
		focusLayerStyle: 'darken',
		removeSuggestionLabel: 'Remove suggestion',
		submitGroupLabel: 'Show all #GROUP_NAME# search results',
		suggestionsLayerLabel: 'Search Suggestions',
		suggestionsNavigationLabel: 'Suggestion Groups Navigation',
		staticLayout: false,
		splitIntoClusters: false,
		noSuggestsText: undefined,
		showEmptyStateSuggestions: true,
		filters: undefined,
		clusterClassMap: {},
		showAllDataPoints: false,
		emptyContainerOnShow: false
	},
	style: {
		themeColor: '#4a4f62', // the theme color affecting headlines, buttons, and links
		accentColor: '#3d8fff',
		suggestions: undefined,
		defaultCss: true, // whether to include the default CSS,
		searchBox: undefined,
		loaderType: 'skeleton', // can be "circle", "square" or "skeleton"
		animationSpeed: 250, // speed of the animations in milliseconds
		additionalCss: undefined, // additional CSS to add to the plugin's stylesheets: e.g. '#ss360-layer{background: red;}'
		redrawTrigger: undefined // a CSS selector to an element which triggers redrawing of the applied styles
	},
	searchBox: {
		placeholder: undefined,
		autofocus: false, // if true, the search box will get focus after initialization
		selector: '#searchBox', // the selector to the search box
		searchButton: '#searchButton', // CSS selector of search buttons
		focusLayer: false, // if true, a layer will be shown when the user focuses on the search input
		preventFormParentSubmit: true, // whether to disable submitting of parenting form
		searchButtonLabel: undefined, // the label of the search button in Site Search 360 custom search inputs, if not set, a magnifying glass icon will be rendered
		_preventBind: false
	},
	results: {
		embedConfig: undefined, // {'url':undefined,'contentBlock':'.page-content-body'}, // if url is given the page will change to that URL and look for the content block there to insert the results
		fullScreenConfig: undefined, // {trigger: '#ss360-search-trigger', caption: 'Search this site'}, trigger is the CSS selector to the element that starts the search full screen overlay and searchCaption the caption on the full screen search page
		caption: 'Found #COUNT# search results for "#QUERY#"', // the caption of the search results
		group: true, // whether results should be grouped if content groups are available
		filters: undefined,
		mergePresetFilters: false,
		num: 9999999, // the maximum number of search results to be shown
		highlightQueryTerms: true, // whether to highlight the query terms in search results
		moreResultsButton: 'See more', // HTML for the more results button, all results will be shown if this is null
		noResultsText: 'Sorry, we have not found any matches for your query.', // the text to show when there are no results
		noResultsRedirect: undefined, // the page to redirect to when there are no results
		queryCorrectionText: 'Did you mean "#CORRECTION#"?', // #CORRECTION# will be replaced automatically by the corrected query
		queryCorrectionRewrite: 'Showing results for "#CORRECTION#"', // #CORRECTION## will be replaced automatically with the rewritten/corrected query
		queryCorrectionOverride: 'Search for "#ORIGINAL_QUERY#" instead',
		searchQueryParamName: 'ss360Query', // the name of the search query parameter
		linksOpenNewTab: false, // should clicking on the result links open a new tab/window?
		showSearchBoxLayover: true, // whether to show search box in search result layover
		showSearchBoxEmbed: false, // whether to show search box in search result block for embed search results
		moreResultsPagingSize: 12, // the number of new results to show each time the more results button is pressed (max: 24)
		orderByRelevanceText: 'Relevance', // the text to be shown in order select box to describe 'order by relevance' option
		redirectOnSingle: false, // whether to redirect instead of showing a single search result
		limitPerGroup: true, // if set to true, the maximum number of search results will be applied to every single content group, otherwise the limit will be spread across all groups, default: true
		stripHttp: false, // if set to true the protocol part (http:// or https://) will be removed from the visible url shown in the search results
		layoverTrigger: undefined, // a CSS selector that points to an element which should trigger the showing of the layover search layer
		lazyLoadImages: true, // whether to lazily load images (once they become nearly visible, instead of loading them all at once)
		infiniteScroll: false, // whether to show more search results once the user scrolls to the end of the result block (will only work if the navigation type is 'tabs' or only one content group has been retrieved)
		hideResultsWithoutImage: false, // whether to hide all results that don't have any image or have a broken image
		sortingLabel: 'Sorting:', // a text label to show next to the sorting selection
		sorting: undefined, // the default sorting option to apply
		cta: [], // array of CTAs to render in the search results, every cta is an object with the following structure: {text: 'The text of the CTA Button', link: 'The link to redirect to after the CTA is clicked (default: no redirect), use #RESULT_URL# to redirect to the result page', renderAsButton: true /* whether to render the cta with button-like style, default: true */, icon: 'ss360:arrow' /* the icon to show inside of the CTA button ('ss360:arrow' - triangle arrow, 'ss360:shopping-cart' - shopping cart icon, svg string, image url, or 'none'), default: 'ss360:arrow', only rendered if renderAsButton is true */, 'clickCallback': undefined /* a callback to call after the CTA button is clicked, receives the event object and result JSON as parameter */, includeContentGroups: undefined /* json array of content group names for which the CTA should be shown */, excludeContentGroups: undefined /* json array of content group names for which the CTA should not be shown */, position: 'left' /* the alignment of the cta button, 'left', 'center' or 'right' - works only for ctaDirection: 'column', for ctaDirection: 'row' all the ctas will be aligned based on the first item in the list  */}
		ctaDirection: 'column', // how to place multiple CTAs in one search result - 'row' x 'column' (default)
		resultTemplate: {
			template: undefined,
			preRenderCallback: undefined,
			templateBuiltCallback: undefined,
			postRenderCallback: undefined,
			variableReplacementPattern: undefined,
			dataPointDefaults: {},
			highlightContext: undefined // css selector where query parts should be highlighted
		},
		hideLayerOnBodyClick: true, // whether to hide the search result layover layer when user clicks outside of the layer (otherwise only the ESC key and close button click will hide the layover layer)
		showContentGroupHeadings: true, // whether to show the content group headings
		semanticMode: false, // whether to generate semeantic urls like /s-t-shirts, only to be used with embed mode
		semanticModeParamName: 's-', // the search query prefix in semantic mode
		semanticModeSpaceCharacter: '-', // the character/string to use instead of space in a semantic mode
		semanticModeKeepTrailingSlash: false,
		placeholderImage: undefined, // by default a striped background will be instead of missing images, set to null to collapse missing images, or to a url to load a placeholder image
		allowHistoryApi: true, // whether to use the history API to push and replace states
		focusResultBlock: true, // whether to focus the result block once the results were loaded (to be able to tab directly to the first search result)
		nameParsing: true, // whether to use filter names and full values in query params instead of filter ids (+ whether to add sorting options to the url)
		sortingParamName: 'ss360Sorting',
		checkImageQuality: true,
		fuzziness: undefined, // the search result fuzziness (between 0 and 5)
		ignoreEnter: false,
		integrationType: undefined, // the integration type - 'layover' (default), 'fullscreen', or 'embed'
		searchResultsLayerLabel: 'Search Results',
		watermarkLabel: 'Powered by Site Search 360',
		contentDataPoint: 'searchSnippet',
		showVariants: true,
		showAlternativeImages: true,
		variantsCountLabel: '+#COUNT# more options available',
		variantsCountLabelSingular: '+#COUNT# more option available',
		categorySearch: undefined,
		sanitizeResults: false,
		navigationBackScrollOffset: 0,
		showRelatedQueries: false,
		relatedQueriesTitle: 'Related Searches:',
		relatedQueriesPosition: 'aboveResultLayer',
		pageDescriptionLabel: 'Showing #COUNT# of #TOTAL# results',
		showCopyLinkToPositionButton: true,
		copyLinkToPositionButtonLabel: 'Copy a link to this position in the list',
		copiedLinkToPositionButtonLabel: 'Link copied',
		_noResultsGroups: [],
		showGuidedQuestions: false,
		_disablePresetFilters: false
	},
	queryTerm: {
		scrollIntoViewBlock: 'start', // how to scroll the text into view on redirect and a single query term match, one of 'start', 'center', 'end' or 'none' (don't scroll into view at all)
		highlightContext: undefined, // a CSS selector to limit a part of the page where redirect query terms will be highlighted
		highlight: true, // whether to highlight parts of the query after redirect to a specific search result
		scrollIntoViewBehavior: 'smooth', // the behavior of the scroll text into view, 'smooth' (the page smoothly scrolls to the text - over a few seconds) or 'auto' (the page instantly jumps to the text)
		highlightColor: '#b5f948', // the background color of highlighted text
		highlightMatchedContent: false, // whether to highlight the matching content on the result page (after the user selects a result)
		scrollOnMultiMatch: false, // whether to scroll to the first match if the matched content occurs multiple times on the page
		tokenize: false, // whether to split longer queries into tokens and highlight each token (e.g. the query 'Site Search 360' would also highglight single 'site', 'search' and '360' words)
		faqContext: undefined, // FAQ selector --> if a FAQ query param is identified, the UI will scroll to this element
		highlightOnRedirect: true, // whether to highlight search terms on redirect
		scrollOnRedirect: true // whether to scroll to the highlighted search term on redirect
	},
	contentGroups: {
		include: undefined, // json array of content group names to be included in the search result
		exclude: undefined, // json array of content group names to be excluded from the search result
		otherName: '', // the name of the results not in any other content group
		ignoreOther: false, // whether or not to ignore the "other" content group
		viewNames: {}, // mapping of content group names set up in the control panel to view names
		classMap: {}
	},
	tracking: {
		providers: [], // how to track, supported values: 'GA' (Google Analytics), 'GTM' (Google Tag Manager)
		searchCallback: undefined, // callback before SERP is reported, SERP events aren't reported if this returns false, you'll get the query as the parameter for the callback
		logQueries: true,
		gaAlias: undefined,
		ignoreQueryParam: false,
		external: undefined,
		addZoovuCid: false
	},
	callbacks: {
		suggestChange: undefined, // callback triggered after suggestion set is changed, takes boolean indicating whether suggestions are visible as argument
		redirect: undefined, // callback to handle search redirects, takes redirect url as parameter, window.location.href is changed by default
		preSearch: undefined, // a callback that is triggered before the search is executed, e.g. to catch empty queries
		postSearch: undefined, // a callback that is triggered after the search results have been populated
		preSuggest: undefined, // a callback that is triggered before the search suggest is executed, takes the query and search box reference as arguments
		searchResult: undefined, // a callback that is triggered after the search is executed, e.g. to build your own result page from the response
		closeLayer: undefined,
		init: undefined, // function to call after initialization is complete
		moreResults: undefined, // a callback to call when the 'Show More Results' button is clicked
		resultImageError: undefined, // a callback to call for 404 images, should return falsy value (if the image should be hidden) or a new placeholder image url, the result of this function has higher priority than the `results.hideResultsWithoutImage` handler, receives the result's DOM Node as first argument
		suggestLine: undefined, // a callback called after a suggestion entry is created
		resultLine: undefined, // a callback called after a search result entry is created
		navigationClick: undefined, // a callback called after a navigation item is clicked, receives the toggled content group name as argument
		preRender: undefined, // a callback called before the search results are rendered (does not get called on 'Show More Results' button click), receives the (grouped) search results as argument, should return a modified search results object if the search results should be modified (e.g. to reorder the content groups), if the received search result object is directly modified, all of the changes will be reflected in the search results
		filterRendered: undefined, // a callback called after the filter options have been rendered
		searchError: undefined, // a callback called if the search request fails (e.g. the user is offline, or the search API is not available)
		suggestPostRender: undefined, // a callback called after the search suggestions have been rendered
		imageLoaded: undefined, // a callback called everytime an image has been loaded in the search results
		queryModification: undefined, // a callback called before the query is execute, should return a modified query
		resultsPreloaded: undefined, // a callback called after a results page has been loaded
		noResultsLoaded: undefined, // a callback called in case no search results or suggestions could be retrieved, you can use this callback to modify some search settings, if the callback returns true, the search/suggestion request will be reattempted with the updated settings, it receives two arguments - the search query and the context (either 'search' or 'suggestions')
		preInit: undefined // a callback called before the search plugin is initialized, receives the ss360Config object as an argument
	},
	accessibility: {
		isMainContent: false, // whether to mark ss360 layer as main content of the page
		resultTopHeadingLevel: 2, // heading level to start with in search result (default h2)
		suggestHeadingLevel: 2, // heading level to use in search suggestions, for content group heading
		searchFieldLabel: 'Search query', // invisible label to be used with screen readers when search field is focused, will only be used if value is not empty and there is no label element associated to the search field,default: 'Search input'
		srSuggestionsHiddenText: 'Search suggestions are hidden', // text to announce @screen reader after search suggestions have been hidden
		srNoSuggestionsText: 'No search suggestions', // text to announce @screen reader if no suggestions are available
		srSuggestionsCountText: '#COUNT# search suggestions shown', // text to announce @screen reader after search suggestions have been shown, #COUNT# will be replaced with the suggestion count
		srOneSuggestionText: 'One search suggestion shown', // text to announce @screen reader after search suggestions have been shown
		srSuggestBoxControlDescription: 'Use the up and down arrows to select a result. Press enter to go to the selected search result. Touch device users can use touch and swipe gestures.' // text to announce @screen reader after search input is focused - describes keyboard controls
	},
	smart404: {
		identifier: 'Page not found', // the string in the title that identifies the page as a 404 page
		cssIdentifier: undefined, // a css selector to an element identifying a 404 page, if the element is present on the page, the page will be considered a 404, this setting overrides the 'identifier' setting if set
		resultSelector: '#ss360-404', // a CSS selector that points to the area in which the alternative links should be shown
		caption: 'Try going here instead:', // caption for 404 results
		num: 12, // the maximum number of results, cannot be greater than 12
		searchResultsLayerLabel: 'Recommended Links'
	},
	layout: {
		mobile: { // below 992px
			type: 'list', // can be "grid", "masonry" or "list", default: "list"
			showImages: true, // whether to show images in search result, default: true
			showSnippet: true, // whether to show text snippet in search result, default: true
			showTitle: true, // whether to show title in search result, default: true
			showDataPoints: true, // whether to show data points in search result, default: true
			showUrl: false, // whether to show link in search result, default: false
			gridColsMd: 2, // grid layout column count for devices between 768px and 991px, default: 2
			gridColsSm: 1 // grid layout column count for devices below 768px, default: 1
		},
		desktop: { // 992 px and larger
			type: 'list', //  can be "grid", "masonry" or "list", default: "list"
			showImages: true, // whether to show images in search result, default: true
			showSnippet: true, // whether to show text snippet in search result, default: true
			showTitle: true, // whether to show title in search result, default: true
			showDataPoints: true, // whether to show data points in search result, default: true
			showUrl: false, // whether to show link in search result, default: false
			gridColsXl: 4, // grid layout column count for devices larger than 1200px, default: 4
			gridColsLg: 3 // grid layout column count for devices between 992px and 1199px, default: 3
		},
		masonryCols: { // how many masonry grid columns to show, minimum width to column count mapping (default: 2 columns below 768px, 3 columns between 768px and 991px, 5 columns between 992px and 1199px and 6 columns above 1200px)
			0: 2,
			768: 3,
			992: 5,
			1200: 6
		},
		singleLineGridTitle: false, // whether to force a single line of the search result title with the 'grid' layout
		navigation: {
			position: 'top', // navigation "top", "left", or "none"
			type: 'tabs', // the navigation layout 'scroll' or 'tabs', for more than 6 (position: 'top') or 10 (position: 'left') content groups the 'scroll' navigation will be used
			tabSpacingPx: 8, // spacing between tabs
			borderRadiusPx: 3, // tab border radius
			tabTitle: '#NAME# (#COUNT#)', // e.g. 'Found 43 Recipes for "curry"'
			showGroupResultCount: true, // whether to show the count of results in specific content group in the navigation
			forceTabs: true, // whether to force tabs to be shown (even for high content group count or a single content group), applied to desktop only
			fallbackToScroll: false, // whether to use scroll navigation instead of tabs on desktop devices (992px and more) when more than 5 (top navigation) or 10 (left navigation) content groups are shown
			showAllResultsTab: true, // whether to show an 'All Results' tab,
			allResultsTabName: 'All Results', // the name of the 'All Results' tab
			allResultsTabTitle: 'All Results (#COUNT#)', // the title of the all results group, e.g. 'Found 43 results for "curry"'
			keepOpenTab: true, // whether to reopen the last focused tab on new query
			allResultsFirst: true, // whether the all results tab should be displayed as first or last tab
			navigationLabel: 'Search Result Navigation',
			preventDropdown: false,
			forceCaption: false, // whether to force the caption to be shown in the search result navigation
			flatTabs: false // whether to force a new chip tab design
		},
		showListGridToggle: false
	},
	voiceSearch: {
		enabled: false, // whether to enable voice search for supported browsers (an microphone icon will be added to your search field if Speech Recognition API is supported)
		lang: 'en-US', // the input language (BCP 47 language tag)
		repositionTrigger: undefined, // a css selector to an element which triggers a repositioning of the voice search icon once clicked
		color: '#333333', // the color of the microphone icon
		autoPosition: true // whether to inline the position attributes to the voice search icon
	},
	filters: {
		enabled: false, // whether to generate and show filter options, default: false
		position: 'left', // where to place the filter view, one of the following: "top", "left"; "top" - filters will be shown above the search results, "left" - filters will be shown to the left of search results + "show filter" button will be added for mobile devices; default: "left" for embeded or fullscreen search results, "top" otherwise
		label: 'Filter', // the label of the filter column, will be also used as screen reader text
		showCounts: true, // whether to show result counts for multiple choice filters
		showQuickDelete: true, // whether to show a "Quick Delete" bar summarizing active filter options and providing a "delete all" option
		deleteAllLabel: 'Reset All', // the label of the "delete all" option
		settings: {}, // range filter settings, e.g. {Price: {unit: '$', step: 1, drawHistogram: false}}
		forceSlideIn: false, // whether to hide the filters by default and only show those once toggled
		toggleButtonLabel: 'Filter results', // the label of the filter toggle button,
		expandedGroupsCount: 6, // number of filter groups to expand by default, following groups will be collapsed (default: 6), set to -1 to expand all filter groups
		multiSelectSearchLabel: 'Search #FILTER_NAME#', // the label of multiselect search input
		multiSelectEmptyState: 'No matching filter options.', // the message to show if the filter option search yields empty results
		multiSelectShowMoreLabel: 'See #COUNT# more',
		multiSelectShowLessLabel: 'See fewer options',
		multiSelectSearchThreshold: 12, // the minimum number of multiselect options required for the search input to be shown
		multiSelectShowMoreThreshold: 12, // the minimum number of multiselect options required for the 'show more options' to be shown
		clearGroupLabel: 'clear', // the label of the clear all filter group options button
		sliderMinUnitLabel: 'Min #UNIT#', // label of the min val range slider input
		sliderMaxUnitLabel: 'Max #UNIT#', // label of the min val range slider input
		submitButtonLabel: 'Set', // the label of the submit filter button
		dateFormatLocale: undefined, // the locale to use when formatting date string
		showOnSingleResult: false, // whether to display filter options ever if those have no influcence on the result set
		preSelect: [], // an array of filter options that will be always included in the filters request (unless a filter option from the same group has been selected by the user)
		skipFilter: 'Skip Filters',
		skipFilterGroup: 'Skip Filter Group',
		selectedFilterOptionsLabel: 'Selected Filter Options',
		dayLabel: 'Day',
		monthLabel: 'Month',
		yearLabel: 'Year',
		_render: true,
		sliderResultCountLabel: '(#COUNT# results)',
		showHiddenFilters: false
	},
	dataPoints: {
		include: [], // data points that should be shown in the UI despite being set as hidden
		exclude: [], // data points that should not be shown in the UI, array of data point names
		single: [], // data points where only the first one should be shown (if multiple values are present), array of data point names
		direction: 'row', // the direction of the data point key-value pairs - whether the data points should be shown as a row or as a column (table)
		showNames: true, // whether to show data point names
		collapseBy: ', ', // the (html) string to be used when merging rows of the structured data table having the same key, default: ', ', e.g. '<br/>', set to null to show data points with the same key in multiple rows
		unique: false, // whether to display only unique values
		displayType: false, // whether to append the data point type
		showOnlyPassOns: false, // whether to show only pass on data points (ecom)
		addTypeClass: false // whether to append the data point type class name to the data point wrapper
	},
	subConfigs: { // a map of sub config ids to sub configs, the sub config overrides a ss360Config settings by providing the full setting path followign the dot notation, can be used for example for localization, e.g. 'de: {"searchBox.placeholder": "Suchen…"}', set the activeSubConfigId to turn on the desired subconfig (this can also be done after the plugin initializes by calling SS360.changeConfig('activeSubConfigId', 'ID');), set the subconfig id to undefined to use the default ss360Config configuration

	},
	errorScreen: {
		offline: {
			title: 'You are offline',
			message: 'It seems there\'s a problem with your network. Please check your internet connection.',
			tryAgain: 'Try again'
		},
		blocked: {
			title: 'Search request blocked',
			message: 'Please check your privacy extensions.',
			tryAgain: 'Try again'
		},
		generic: {
			title: 'Oops!',
			message: 'Something went wrong. Sorry about that!',
			tryAgain: 'Try again'
		},
		siteId: {
			title: 'Site ID missing',
			message: 'Please check your configuration code and make sure to provide a valid site ID.',
			tryAgain: 'Learn more'
		},
		ipBlocked: {
			title: 'Blocked',
			message: 'You are not allowed to use this service.'
		}
	},
	activeSubConfigId: undefined,
	language: 'en', // the language of search interface, available options: 'en', 'de', 'fr', 'nl'
	forceBranding: false, // whether to force the site search 360 logo to be shown in the search results/suggestions - use true to always show, 'columbo' to show the same branding as on a COLUMBO plan, or 'free' to show the same branding as on a FREE plan
	_cacheQueries: undefined,
	ecom: false,
	ecomDev: false,
	ecomStage: false,
	ignoreShopSysHeader: false,
	similarContent: {
		active: false,
		buttonLabel: 'More like this',
		title: 'More like this',
		limit: 3,
		isRelationSearch: false,
		isViualSimilaritySearch: false,
		dataPoints: [],
		emptyStateTitle: 'Oops!',
		emptyStateText: 'We couldn\'t find any similar results for this item. Please try another one.',
		_appendToParent: false,
		displayType: 'inline',
		drawerPosition: 'right',
		useResultTemplate: false
	},
	noResultsPage: {
		title: 'We\'re sorry, we couldn\'t find an exact match for "#QUERY#"',
		description: 'Please try another search term or adjust your query in case there\'s a typo we didn\'t catch.',
		content: [
			{
				type: 'searchBox'
			},
			{
				type: 'relatedQueries'
			},
			{
				type: 'fuzzyResults',
				title: 'Other results you might find useful',
				fallback: {
					type: 'popularResults',
					title: 'Some of our popular content'
				}
			},
			{
				type: 'popularQueries',
				title: 'People often search for'
			}
		]
	},
	zoe: {
		region: 'eu',
		showChat: false,
		locale: 'en-US',
		chatLabel: 'Chat',
		context: {}
	},
	comparison: {
		enabled: false,
		showCta: false,
		tableStyle: 'default',
		buttonLabel: 'Compare',
		tooManyProductsLabel: 'Only first 4 products will be compared.',
		comparisonLabel: 'Compare',
		comparisonCount: '#COUNT# items',
		heading: 'Compare Products',
		showDifferencesText: 'Only show differences',
		ctaText: 'Add to cart'
	},
	additionalRequestParams: {},
	additionalHeaderParams: {}
};

export default DefaultConfig;