'use strict';
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import StringHelper from '../../global/StringHelper';
var SearchSuggest = /** @class */ (function () {
    function SearchSuggest(data) {
        this.variants = [];
        this.plain = data;
        try {
            if (data instanceof Array) {
                this.variants = JSON.parse(JSON.stringify(data));
                this.suggest = this.variants.splice(0, 1)[0];
                this.suggest.variants = this.variants;
            }
            else {
                this.suggest = data;
                this.suggest.variants = [];
            }
            this.pinned = this.suggest.pinned;
            this.relevance = this.suggest.relevance;
        }
        catch (ex) {
            // ccl
        }
    }
    SearchSuggest.prototype.setOriginalContentGroup = function (group) {
        this.suggest._originalGroup = group;
    };
    SearchSuggest.prototype.getOriginalContentGroup = function () {
        return this.suggest._originalGroup;
    };
    SearchSuggest.prototype.getLink = function (highlightMatchedContent, query, isZoovuBranding) {
        if (highlightMatchedContent === void 0) { highlightMatchedContent = false; }
        if (isZoovuBranding === void 0) { isZoovuBranding = false; }
        var link = this.suggest.link;
        if (highlightMatchedContent && link !== undefined && link.indexOf('ss360SearchTerm=') === -1 && link.indexOf('zSearchTerm=') === -1) {
            var linkParts = link.split('#');
            var linkBase = linkParts[0];
            var linkHash = linkParts.length === 2 ? "#".concat(linkParts[1]) : '';
            var paramChar = linkBase.indexOf('?') !== -1 ? '&' : '?';
            var term = isZoovuBranding ? 'zSearchTerm' : 'ss360SearchTerm';
            link = "".concat(linkBase).concat(paramChar).concat(term, "=").concat(encodeURIComponent(StringHelper.unescapeHtml(query))).concat(linkHash);
        }
        return link;
    };
    SearchSuggest.prototype.getName = function () {
        return this.suggest.name;
    };
    SearchSuggest.prototype.getType = function () {
        return this.suggest.type;
    };
    SearchSuggest.prototype.getHtml = function () {
        return this.suggest.html;
    };
    SearchSuggest.prototype.getImage = function (optimized) {
        if (optimized === void 0) { optimized = false; }
        if (optimized) {
            var originalImage = this.getOriginalImage();
            var images = (this.suggest.images || []);
            for (var i = 0; i < images.length; i++) {
                var entry = images[i];
                if (entry !== undefined && (entry.minUrl || '').length > 0 && entry.url === originalImage) {
                    return entry.minUrl;
                }
            }
            // fallback
            var dataPoints = this.getDataPoints();
            for (var i = 0; i < dataPoints.length; i++) {
                var dp = dataPoints[i];
                if (dp.key === 'resultImageUrl' && dp.value !== undefined && dp.value !== null && dp.value.length > 0) {
                    return dp.value;
                }
            }
        }
        return this.suggest.image;
    };
    SearchSuggest.prototype.getOriginalImage = function () {
        return this.suggest.image;
    };
    SearchSuggest.prototype.getDataPoints = function (contentDataPointName) {
        var dataPoints = contentDataPointName !== undefined && !this.hasContent() ? (this.suggest.dataPoints || []).filter(function (dp) { return dp.key !== contentDataPointName; }) : (this.suggest.dataPoints || []);
        return dataPoints.reduce(function (acc, dataPoint) {
            if (dataPoint.values === undefined || dataPoint.values.length === 0) {
                acc.push(dataPoint);
            }
            else {
                dataPoint.values.forEach(function (value) {
                    var dp = __assign(__assign({}, dataPoint), { value: value });
                    dp.values = undefined;
                    acc.push(dp);
                });
            }
            return acc;
        }, []);
    };
    SearchSuggest.prototype.hasImage = function () {
        var image = this.suggest.image;
        return image !== undefined && image !== null && image.length > 0;
    };
    SearchSuggest.prototype.setPlaceholderImage = function (image) {
        if (this.hasImage() || image === undefined || image === null || image.length === 0)
            return;
        this.suggest.image = image;
    };
    SearchSuggest.prototype.hasContent = function () {
        var content = this.suggest.content;
        return content !== undefined && content !== null && content.length > 0;
    };
    SearchSuggest.prototype.getContent = function (contentDataPointName) {
        if (contentDataPointName !== undefined && !this.hasContent()) {
            var dp = this.getDataPoints().filter(function (dp) { return dp.key === contentDataPointName; })[0];
            if (dp !== undefined) {
                return dp.value;
            }
        }
        return this.suggest.content;
    };
    SearchSuggest.prototype.getId = function () {
        return this.suggest.identifier !== undefined ? this.suggest.identifier : this.suggest.link;
    };
    SearchSuggest.prototype.getGroupId = function () {
        return this.suggest.groupId;
    };
    SearchSuggest.prototype.getIdentifier = function () {
        return this.suggest.identifier;
    };
    SearchSuggest.prototype.getAllVariantImages = function () {
        return this.variants.reduce(function (acc, variant) {
            var optimizedImage;
            if (variant.images !== undefined && (variant.images || []).length > 0) {
                optimizedImage = variant.images[0].minUrl;
            }
            if ((optimizedImage || '').length === 0) {
                optimizedImage = ((variant.dataPoints || []).filter(function (dp) { return dp.key === 'resultImageUrl' && dp.value !== undefined && dp.value !== null && dp.value.length > 0; })[0] || {}).value;
            }
            if ((optimizedImage || '').length === 0) {
                optimizedImage = undefined;
            }
            acc.push({
                name: variant.name,
                image: variant.image,
                optimizedImage: optimizedImage,
                identifier: variant.identifier || variant.name,
                link: variant.link
            });
            return acc;
        }, [{
                name: this.getName(),
                image: this.getOriginalImage() || this.getImage(false),
                optimizedImage: this.getImage(true),
                identifier: this.getIdentifier() || this.getName(),
                link: this.getLink()
            }]);
    };
    SearchSuggest.prototype.getUniqueVariantImageCount = function () {
        return Object.keys(this.getAllVariantImages().reduce(function (acc, entry) {
            acc[entry.image] = true;
            return acc;
        }, {})).length;
    };
    SearchSuggest.prototype.getImages = function () {
        var originalImage = this.getOriginalImage() || this.getImage(false);
        var image = this.getImage(true);
        return (this.suggest.images || []).reduce(function (acc, altImg) {
            var img = altImg.url;
            if (img !== undefined && img !== null && img.length > 0 && acc.indexOf(img) === -1 && img !== originalImage && img !== image) {
                if ((altImg.minUrl || '').length > 0 && acc.indexOf(altImg.minUrl) === -1) {
                    acc.push(altImg.minUrl);
                }
                else {
                    acc.push(img);
                }
            }
            return acc;
        }, [image || originalImage]).filter(function (img) { return img !== null && img !== undefined && img.length > 0; });
    };
    SearchSuggest.prototype.equals = function (other) {
        if (other === this) {
            return true;
        }
        if (this.getName() !== other.getName()) {
            return false;
        }
        if (this.getImage() !== other.getImage()) {
            return false;
        }
        if (this.getContent() !== other.getContent()) {
            return false;
        }
        if (this.getLink() !== other.getLink()) {
            return false;
        }
        var thisDataPoints = this.getDataPoints();
        var otherDataPoints = other.getDataPoints();
        if (thisDataPoints.length !== otherDataPoints.length) {
            return false;
        }
        for (var i = 0; i < thisDataPoints.length; i++) {
            var a = thisDataPoints[i];
            var b = otherDataPoints[i];
            if (a.key !== b.key || a.show !== b.show || a.value !== b.value) {
                return false;
            }
        }
        return true;
    };
    SearchSuggest.prototype.isResizedImage = function () {
        return this.getImage(true) !== this.getImage(false);
    };
    SearchSuggest.prototype.getRelationTypes = function () {
        return this.suggest.relationTypes;
    };
    return SearchSuggest;
}());
export default SearchSuggest;
